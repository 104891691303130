import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import Homecarousel from './Homecarousel';
import Home from './Home';
import MenuCategory from './MenuCategory';
import Footer from './Footer';
import MenuItems from './MenuItems';





const Routing = () => {
    return(
        <BrowserRouter>
            
            <Route exact path="/" component={Home}/>
            <Route exact path="/MenuCategory" component={MenuCategory}/>
            <Route exact path="/MenuItems/:_id" component={MenuItems}/>
            <Route exact path="/Homecarousel" component={Homecarousel}/>
            <Route exact path="/Footer" component={Footer}/>
            
            
        </BrowserRouter>
    )
}

export default Routing;
