import React,{Component} from 'react';
import './Header.css';
import {Link} from 'react-router-dom';




const menuUrl = "https://funmento.onrender.com/menu"


class MenuCategory extends Component {
    constructor(props) {
        super(props);
        console.log(">>> inside MenuCatState", props)

        this.state={
            menuCategory:'',
            
        }
    }

    renderMenu = (data) => {
        if (data){
            data.sort((a, b) => a._id - b._id);
            return data.map((item) => {
                return(
                    <>
                       
                        <div className="card addColor">
                            <img src={item.menuImage} className="card-img-top" alt="menuImages"/>
                            <div className="card-body mobileViewRel">
                                <center>
                                    <h4 className="card-title mobileViewAbs">{item.menu}</h4>
                                </center>
                                
                            </div>
                            <div className="overlay"><br/>
                                <h5 className="note">{item.menu}</h5>
                                <hr style={{color:"gold"}}/>
                                <center>
                                    
                                    
                                    <hr style={{color:"gold"}}/>
                                    <Link to={`/MenuItems/${item._id}`} key={item._id}>                                    
                                        <span><button type="button" className="btn btn-outline-warning" onClick={localStorage.setItem('catID', item._id)}>{item.items1}</button></span>
                                        <span><button type="button" className="btn btn-outline-warning" onClick={localStorage.setItem('catID', item._id)}>{item.items2}</button></span>
                                        <span><button type="button" className="btn btn-outline-warning" onClick={localStorage.setItem('catID', item._id)}>{item.items3}</button></span>
                                        <span><button type="button" className="btn btn-outline-warning" onClick={localStorage.setItem('catID', item._id)}>{item.items4}</button></span>
                                        
                                    </Link>
                                    <br/><br/>
                                    <p>
                                        <Link to={`/MenuItems/${item._id}`} key={item._id} style={{fontFamily:"Georgia, 'Times New Roman', Times, serif", fontSize:"20px", textDecoration:"none", color:"white", fontWeight:"bold"}}><button type="button" className="btn btn-outline-warning" onClick={localStorage.setItem('catID', item._id)}>Start an order</button></Link>
                                    </p>
                                </center>
                            </div>
                        </div>
                       
                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }


    render(){
        console.log(">>> Inside menuCatRender", this.state)
        return(
            <>
             
                <div className="container putinplace">
                    <div className='fitIn'>
                        <center><h3 className="text">Our Dishes</h3></center>
                        <div className="row">
                            <div className ="col">
                                {this.renderMenu(this.state.menuCategory)}
                            </div>
                        
                        </div>
                    
                    </div>

                </div>
                
            
            </>
            
        )
    }

    componentDidMount(){
        console.log(">>> Inside MenuCategoryDidMount", this.state)
        fetch(menuUrl, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({menuCategory:data})
        })
       

        

    }
}

export default MenuCategory;