import React,{Component} from 'react';
import './Dashboard.css';
import Carousel from 'react-bootstrap/Carousel';
// import Button from 'react-bootstrap/Button';



class Homecarousel extends Component{

    constructor(props){
        super(props)
        console.log(">>>Inside Constructor")

        this.state = {
           imageFIles:''
            


        };

    }

              
    render(){
        console.log (">>> Inside Render", this.state)
               
        return(
            <>
                
                <div className="Dashbackground">
                    <div className='carouselSetting'>
                        <Carousel>
                            <Carousel.Item className="carouselBx">
                                <img className="d-block w-100 imgsize" src="https://i.ibb.co/jLTCW5J/side-close-up-view-pilaf-appetizing-pilaf-with-rice-meat-dried-fruits2.jpg" alt="FirstSlide"/>
                                {/* <Carousel.Caption>
                                    <div className="carouselBx2">
                                        <Button variant="primary"  onClick={()=>this.props.history.push('/contact')}>Contact Us</Button>{' '}
                                    </div>
                                    
                                </Carousel.Caption> */}
                            </Carousel.Item>
                            
                            <Carousel.Item>
                                <img className="d-block w-100 imgsize" src="https://i.ibb.co/p6HH8VP/side-close-up-view-pilaf-appetizing-pilaf-with-rice-meat-dried-fruits.jpg" alt="FirstSlide"/>
                                {/* <Carousel.Caption>
                                    <div className="carouselBx2">
                                        <Button variant="warning" onClick={()=>this.props.history.push('/services')}>Order Now</Button>{' '}
                                    </div>
                                    
                                </Carousel.Caption> */}
                            </Carousel.Item>

                            <Carousel.Item>
                                <img className="d-block w-100 imgsize" src="https://i.ibb.co/sWrnWZm/side-close-up-view-pilaf-appetizing-pilaf-with-rice-meat-dried-fruits5.jpg" alt="FirstSlide"/>
                                {/* <Carousel.Caption>
                                    <div className="carouselBx2">
                                        <Button variant="danger" onClick={()=>this.props.history.push('/services')}>Get in touch</Button>{' '}
                                    </div>
                                    
                                </Carousel.Caption> */}
                            </Carousel.Item>
                            
                        </Carousel>
                        <br/><br/>
                    </div>
                                      

                                        
                </div>
                    
                
                
            
            </>
        )
    }
    componentDidMount(){
        console.log (">>> Inside DidMount")
        
        
    }
}
export default Homecarousel;

