import React, { Component } from 'react';
import './Header.css';
import {withRouter} from 'react-router-dom';
// import Adlogin from './Adlogin';





class Footer extends Component {
    constructor(props){
        super(props);
        console.log(">>> inside funmiState", props)
        this.state={
            
            
        }
    }

       
    render(){
        console.log(">>>Inside RMrender",this.state)
        
        
        return (
            <>
                <div className="FooterBgC">
                    <span>
                        <div>
                            
                        </div>
                        

                    </span>
                    <span>
                        <div>
                            

                        </div>
                        
                    </span>
                    
                    <span>
                        
                        
                    
                    </span>

                    
                   
                </div>
                
                              
                
            
            </>
        )
    }

    componentDidMount(){
        console.log (">>> Inside RMdidMount")
        
        
        
      
    }
    
    
}


export default withRouter(Footer);