import React,{Component} from 'react';
import './Header.css';
import Newheader from './Newheader';
import Footer from './Footer';
import {EmailShareButton,FacebookShareButton,LinkedinShareButton,TelegramShareButton,TwitterShareButton,WhatsappShareButton} from "react-share";
import {FacebookShareCount} from "react-share";
import {EmailIcon,FacebookIcon,LinkedinIcon,TelegramIcon,TwitterIcon,WhatsappIcon,} from "react-share";






const menuurl = "https://funmento.onrender.com/meals?mealID="
const shareUrl = "http://funmento.org/MenuItems/";
const qtyUrl = "https://funmento.onrender.com/qty/";
const riceqtyUrl = "https://funmento.onrender.com/riceqty/";
const soupqtyUrl = "https://funmento.onrender.com/soupqty/";
const postCart = "https://funmento.onrender.com/addtocart/";
const getCart = "https://funmento.onrender.com/cart?cartUserID=";
// const mealurl = "https://funmento.onrender.com/meals?menuID="
// const getCart = "https://funmento.onrender.com/cart?cartUserID=";

class MenuItems extends Component {
    constructor(props) {
        super(props);
        console.log(">>> inside MenuItmeConstructor", props)

        this.state={
            menuList:'',
            categoryName:'',
            LinkUrl:'',
            
            orderQuantity:'',
            orderAmount:'',
            orderQty:'',
            mealPrice:'',
            mealName:'',
            mealImage:'',
            
            SelectedMeal:'',
            cart:[],
            OrdermealID: Math.floor(Math.random()*10000),
            totalOrder: '',
            userID: Math.floor(Math.random()*10000000000),
            cartData:'',
            riceorderQty:''
            
            
        }
    }

    handleChange = (event) => {
        if(event.target.value === "Small Tray") {
            this.setState({
                [event.target.name]:"1"
                
               
            })
        }

        else if(event.target.value === "Large Tray") {
            this.setState({
                [event.target.name]:"2"
                
               
            })
        }

        else if(event.target.value === "2 Litres") {
            this.setState({
                [event.target.name]:"1"
                
               
            })
        }

        else if(event.target.value === "4 Litres") {
            this.setState({
                [event.target.name]:"2"
                
               
            })
        }

        else{
            this.setState({
                [event.target.name]:event.target.value
                
               
            })
        }
        
    }

    cartFirstStep(){
        const menuID = localStorage.getItem('menuID');
        const getProduct = this.state.menuList;
        // const userCode = localStorage.getItem('userID')
        
        
       
        
       
        //find a product from an existing state data (array)
        const findProduct = getProduct.find((item)=> item._id === menuID);
        
        // this.setState((prevState) => ({
        //     cart: [...prevState.cart, findProduct];
            
        // }));
        // this.myTimer = setTimeout(()=>{
        //     localStorage.setItem('cartList', JSON.stringify(this.state.cart));
        //     const existingData = JSON.parse(localStorage.getItem('cartList')) || [];
        //     localStorage.setItem('cartList2', JSON.stringify(existingData));
        // },1000)
        
        
        let order = [];
        order.push(findProduct)
        this.setState({
            cart:[...order]
        })

        

        this.myTimer = setTimeout(() => {

            const allCartItem = this.state.cart
            allCartItem.map((item)=>{
                this.setState({mealPrice:item.price});
                this.setState({mealName:item.mealname});
                this.setState({mealImage:item.mealImage});
                return 'ok'
                
            })
            
            let cartData = {
                orderedMeals:this.state.mealName,
                orderID:this.state.OrdermealID,
                orderQuantity:this.state.orderQuantity,
                mealPrice:this.state.mealPrice,
                mealImage:this.state.mealImage,
                totalAmount:parseFloat(`${this.state.mealPrice}`*`${this.state.orderQuantity}`),
                userID:localStorage.getItem('userID')
            }
            let cartPost = fetch(`${postCart}`, {
                method: 'post',

                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    cartData
                    
                )
                
            });
            alert('Item Added to Cart')
            

            console.log('cartPost>  ' + cartPost)
          

            // fetch(`${getCart}${userCode}`, {method:'GET'})
            // .then((res) => res.json())
            // .then((data) => {
            //     this.setState({
            //         cartData:data
                    
            //     })
            // })
            
        },1000)

       
        
            
        
    }

    
    addTocartNow(){
        this.cartFirstStep();
        
    }



    renderQty = (data) => {
        if(data){
            data.sort((a, b) => a.qty - b.qty);
            return data.map((item)=> {
                return(
                    <option key={item.qty} value = {item.qty}>
                        {item.qty}
                    </option>
                )
            })
        }
    }

    // renderCart = (data) => {
    //     if (data){
    //         data.sort((a, b) => a._id - b._id);
    //         return data.map((item) => {
    //             return(
    //                 <>
                       
    //                     <div className="card">
    //                         <img src={item.menuImage} className="card-img-top" alt="menuImages"/>
    //                         <div className="card-body mobileViewRel">
    //                             <center>
    //                                 <p className="card-title mobileViewAbs">{item.mealname}</p>
    //                             </center>
                                
    //                         </div>
                            
    //                     </div>
                       
    //                 </>
                            
    //             )   

    //         })
    //     }
        
    // }


    renderMenu = (data) => {
        if (data){
            data.sort((a, b) => a.mealname - b.mealname);
            return data.map((item) => {
                return(
                    <>
                        <center>
                            <div className="card sizeOfImage">
                                <img src={item.mealImage} className="card-img-top" alt="menuImages"/>
                                <div>
                                    <span><h5 >{item.mealname}</h5></span>
                                    

                                </div>
                                <div className='placePrice'>
                                    <p className='makesuperscript'>$</p>
                                    
                                </div>
                                <div className='placePrice2'>
                                    <h4>{item.price}</h4>
                                </div>

                                <div className="overlayNew"><br/>
                                    <h5 className="note">{item.menu}</h5>
                                    <center>
                                        
                                        <center>
                                            <hr/>
                                            <span className='gap'>
                                                <FacebookShareButton url={this.state.LinkUrl}>
                                                
                                                    <span>
                                                        <FacebookIcon size="32px" round={true}/>
                                                    </span>
                                                    <span>
                                                        <FacebookShareCount url={this.state.LinkUrl} />
                                                    </span>
                                                </FacebookShareButton>
                                                
                                            </span>
                                            <span className='gap'>
                                                <LinkedinShareButton url={this.state.LinkUrl}>
                                                    <LinkedinIcon size="32px" round={true}/>
                                                </LinkedinShareButton>
                                                
                                            </span>
                                            <span className='gap'>
                                                <TelegramShareButton url={this.state.LinkUrl}>
                                                    <TelegramIcon size="32px" round={true}/>
                                                </TelegramShareButton>
                                                
                                            </span>
                                            <span className='gap'>
                                                <TwitterShareButton url={this.state.LinkUrl}>
                                                    <TwitterIcon size="32px" round={true}/>
                                                </TwitterShareButton>
                                                
                                            </span>
                                            <span className='gap'>
                                                <WhatsappShareButton url={this.state.LinkUrl}>
                                                    <WhatsappIcon size="32px" round={true}/>
                                                </WhatsappShareButton>
                                                
                                            </span>
                                            <span className='gap'>
                                                <EmailShareButton url={this.state.LinkUrl}>
                                                    <EmailIcon size="32px" round={true}/>
                                                </EmailShareButton>
                                                
                                            </span>
                                            <hr/>
                                        </center>
                                                                                    
                                        <br/>
                                        <div className='col-4'>
                                            <h5>
                                                <select className="form-select mb-3" name="orderQuantity" require placeholder="Ouantity" onChange={this.handleChange}>
                                                    <option selected value = ''>Qty</option>
                                                    {this.renderQty(this.state.orderQty)}
                                                </select>
                                            </h5>
                                        </div>
                                       
                                        <span>
                                           <button type="button" className="btn btn-warning gapIt" onMouseOver={()=>{localStorage.setItem('menuID', parseInt(item._id))}} onClick={()=>{this.addTocartNow()}}> Add to Cart</button>
                                        </span>
                                        <span>
                                           <button type="button" className="btn btn-danger" onClick={''}> Buy Now</button>
                                        </span>
                                    </center>
                                </div>
                                
                            </div>
                        </center>
                        
                       
                    </>
                            
                )   

            })
        }
        else{
            return(
                <center>
                    <div>
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-secondary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-border text-warning" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </center>
            )
        }   
    }


    render(){
        console.log(">>> Inside RESrender", this.state)
        return(
            <>
                <Newheader/>
                <div className="">
                    <div className='placeNicely'>
                        <center><h3 className="text">{this.state.categoryName}</h3></center>
                        <div className="row">
                            <div className ="col">
                                {this.renderMenu(this.state.menuList)}
                            </div>
                        
                        </div>
                    
                    </div>

                </div>
                <Footer/>
                
                
            
            </>
            
        )
    }

    componentDidMount(){
        console.log (">>> Inside MenuList")
        const menuID = this.props.match.params._id;
        const LinkUp = `${shareUrl}${menuID}`;
        const userCode = localStorage.getItem('userID');
        
        
        fetch(`${menuurl}${menuID}`, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({
                menuList:data,
                LinkUrl:LinkUp
            })
           
        })

        if(menuID == 1){
            fetch(`${riceqtyUrl}`, {method:'GET'})
                .then((res) => res.json())
                .then((data) => {
                this.setState({
                    orderQty:data
                    
                })
            })
        }

        else if(menuID == 3) {
            fetch(`${soupqtyUrl}`, {method:'GET'})
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    orderQty:data
                    
                })
            })
        }

        else {
            fetch(`${qtyUrl}`, {method:'GET'})
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    orderQty:data
                    
                })
            })
        }
        
        fetch(`${getCart}${userCode}`, {method:'GET'})
        .then((res) => res.json())
        .then((data) => {
            this.setState({
                cartData:data,
                
            })
            
        })

        const user = localStorage.getItem('userID');
        if(user===null){
            localStorage.setItem('userID',this.state.userID )
            
        }

       
               
    }
}

export default MenuItems;