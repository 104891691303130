import React from 'react';
import HomeCarousel from './Homecarousel';
import Newheader from './Newheader';
import MenuCategory from './MenuCategory';
import Footer from './Footer';






const Home = (props) => {
    
    
    return(
        <>
            <Newheader/>
            <HomeCarousel/>
            <MenuCategory/>
            <Footer/>
        </>
    )
}

export default Home;