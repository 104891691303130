import React, { Component } from 'react';
import './Header.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CloseButton from 'react-bootstrap/CloseButton';
import {withRouter} from 'react-router-dom';
// import Adlogin from './Adlogin';
import {Link} from 'react-router-dom';
import NumberFormat from 'react-number-format';



const getCart = "https://funmento.onrender.com/cart?cartUserID=";

class Newheader extends Component {
    constructor(props){
        super(props);
        console.log(">>> inside nanetConstructor", props)
        this.state={
            cartData: '',
            cartColor: '',
            cartTotal:'',
            TotalTax:'',
            orderTotal:0            
        }
    }

    handleShow(){
        this.setState({show:true})
    }

    handleClose(){
        this.setState({show:false})
    }

    handleCartEvent(){
        if(this.state.cartData.length>0){
            this.handleShowCart()
            
        }
        else{
            alert('Your Cart is Empty!')
            this.handleCloseCart()
            
        }

    }

    handleShowCart(){
        this.setState({showCart:true})
        
    }

    handleCloseCart(){
        this.setState({showCart:false})
    }

    // handleLogout = () => {
    //     this.setState({userdata:''})
    //     localStorage.removeItem('userdata')
    //     localStorage.removeItem('rtk')
    //     this.setState({Blogin:true});
    // }

    

    renderCart = (data) => {
        if (data){
            data.sort((a, b) => a._id - b._id);
            return data.map((item) => {
                return(
                    <>
                         <hr style={{color:'silver'}}/>
                       <div>
                            <span>
                                <img src={item.mealImage} className="CartImageSize" alt="menuImages"/>
                            </span>
                            <span>
                                <span className='alignCartText'>
                                    {item.orderedMeals}:  Price: {item.mealPrice} Qty: {item.orderQuantity}
                                </span>
                               
                            </span>
                       </div>
                      
                        
                       
                    </>
                            
                )   

            })
        }
        
        
    }
    
    render(){
        console.log(">>>Inside RMrender",this.state)
        var CartItems = this.state.cartData;
        if(CartItems.length>0){
            var ItemCount = CartItems.length
        }
        
        return (
            <>
                <div className="headerbackgrd">
                    <span>
                        <div>
                            <Link to="/">
                                <img src="https://i.ibb.co/gPX9Bsq/new-logo.png" className="adjustName" alt="menu"/>
                            </Link>
                        </div>
                        

                    </span>
                    {/* <span>
                        <div>
                            <Link to="/">
                                <img src= "https://i.ibb.co/0DHL07w/new-logo.png" className="logo" alt="logo"/>
                            </Link>
                        </div>
                        
                    </span> */}
                    
                    <span>
                        
                        <i type="button" className="fa fa-bars size2" onClick={()=>this.handleShow()}></i>
                    
                    </span>

                    <span>
                        <div>
                            <span className="Ikem"><i type="button" className="fa fa-user Ikem" onClick={()=>this.handleShow()}></i></span>
                        </div>
                    </span>
                    
                    <span>
                        <div>
                        <span className="Adriel"><i type="button" className="fa fa-cart-plus Adriel" onClick={()=>this.handleCartEvent()}></i></span>
                        </div>
                    </span>

                    <span>
                        <div className={this.state.cartColor}>
                        <span><center><p type="button" className="AdrielChizaram" onClick={()=>this.handleShowCart()}>{ItemCount}</p></center></span>
                        </div>
                    </span>
                   
                </div>
                <div className="headerbackgrd2">
                    <span>
                        <center>
                            
                            <span className='fontopt1' type="button" onClick={()=>{this.props.history.push('/')}}>Our Dishes</span> 
                            
                        </center>
                        
                        
                    </span>

                    <span>
                        <center>
                        <span className='fontopt1' type="button" onClick={()=>this.handleCartEvent()}>View Orders </span>
                        
                        </center>
                                                
                    </span>
                    
                    <span>
                        <center>
                        <span className='fontopt1'>Catering </span>
                        </center>
                        
                        
                    </span>

                    <span>
                        <center>
                        <span className='fontopt1'>Contact us </span>
                        </center>
                        
                        
                    </span>

                    <span>
                        <center>
                        <span className='fontoptphone'>+1 (647) 740-8870 </span> 
                        </center>
                        
                        
                    </span>
                    
                </div>
                
                <Offcanvas show={this.state.show}>
                    
                    <div className="headerbackgrd3">
                        <Offcanvas.Body>
                            <div className="bg-dark p-3 size3">
                                <CloseButton variant="white" onClick={()=>this.handleClose()}/>
                                
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/');this.handleClose()}}>Register</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('./');this.handleClose()}}>Our Dishes</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/MenuItems/4');this.handleClose()}}>Combos</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/Occupancy');this.handleClose()}}>Track Orders</p>
                                </center>
                            </div>
                            <div className="menu">
                                <center>
                                    <p className="menulist" type="button" onClick={()=>{this.props.history.push('/SalesReport');this.handleClose()}}>Contact Us</p>
                                </center>
                            </div>
                            
                            
                        </Offcanvas.Body>
                    </div>
                    
                </Offcanvas>

                <Offcanvas show={this.state.showCart}>
                    
                    <div className="headerbackgrd3">
                        <Offcanvas.Body>
                            <div className="bg-dark p-3 size3">
                                <CloseButton variant="white" onClick={()=>this.handleCloseCart()}/>
                                
                            </div>
                            <div className='setCartData'>
                                
                                <div className='Cartmenu'>
                                    <span className='relatOrd'>
                                        <center>
                                            <p className="menulistNow">Your Orders</p>
                                        </center>
                                    </span>

                                    
                                </div>
                            
                                
                                <div className='tryDis'>
                                    {this.renderCart(this.state.cartData)}
                                </div>
                                <hr style={{color:'yellow'}}/>
                                <div>
                                    <div className='cartTotalFormt'>Items: <div className='CartNumberFMT'><NumberFormat value={this.state.cartTotal}thousandSeparator={true}displayType={"text"}/></div></div>
                                </div>
                                {/* <div>
                                    <div className='cartTotalFormt'>Delivery/Handling: <div className='CartNumberFMT'><NumberFormat value= {this.state.DeliveryAndHandling}thousandSeparator={true}displayType={"text"}/></div></div>
                                </div> */}
                                <div>
                                    <div className='cartTotalFormt'>Total before Tax: <div className='CartNumberFMT'><NumberFormat value= {this.state.cartTotal}thousandSeparator={true}displayType={"text"}/></div></div>
                                </div>
                                <div>
                                    <div className='cartTotalFormt'>Estimated HST/GST: <div className='CartNumberFMT'><NumberFormat value= {this.state.TotalTax}thousandSeparator={true}displayType={"text"}/></div></div>
                                </div>
                                <hr style={{color:'yellow'}}/>
                                <div>
                                    <div className='cartTotalFMT'>Order Total: <div className='CartNumberForMaT'><NumberFormat value= {this.state.orderTotal}thousandSeparator={true}displayType={"text"}/></div></div>
                                </div>
                            </div>

                            <center>
                                    
                                <button className="btn btn-warning btnHeight">Checkout Order</button>
                                        
                            </center>
                            
                            
                            
                            
                            
                        </Offcanvas.Body>
                    </div>
                    
                </Offcanvas>
                
                
            
            </>
        )
    }

    componentDidMount(){
        console.log (">>> Inside RMdidMount")
        const userCode = localStorage.getItem('userID');
        

        this.myTimer = setInterval(() => {
            if(userCode!==null){
                
                    fetch(`${getCart}${userCode}`, {method:'GET'})
                    .then((res) => res.json())
                    .then((data) => {
                        this.setState({
                            cartData:data
                           
                           
                        })
                        
                        if(this.state.cartData.length>0){
                            this.setState({
                                cartColor:'Visible'
                            })
                        }
                        else{
                            this.setState({
                                cartColor:'hidden'
                            })
                        }

                        var cartAmount = data.map(item => item).reduce((totals, item) =>{
                            return totals + parseInt(item.totalAmount)
                        }, 0);
                       
                        this.setState({cartTotal:cartAmount.toFixed(2)})

                        var Tax = data.map(item => item).reduce((totals, item) =>{
                            return totals + parseInt(item.totalAmount)
                        }, 0);

                        let comptax = Tax * 0.15;
                        this.setState({TotalTax: comptax.toFixed(2)})

                        const orderTot = cartAmount + comptax;
                        this.setState({orderTotal: orderTot.toFixed(2)});

                
                        // var Delivery = data.map(item => item).reduce((totals, item) =>{
                        //     return totals + parseInt(item.totalAmount)
                        // }, 0);
                        
                        // let delcomp = Delivery.toFixed(2);
                        // this.setState({DeliveryAndHandling:delcomp / 3})

                        

                    })
                    
                
            }

           
           
            
        },1000)
        

        
        
        // fetch(`${checkinDataUrl}`, {method:'GET'})
        // .then((res) => res.json())
        // .then((data) => {
            
        //     this.setState({
        //         checkinData:data.map(item => item.roomStatus).filter(item=> item !=='green'&& item !=='black')
                
        //     })
        //     this.setState({
        //         vacantData:data.map(item => item.roomStatus).filter(item=> item ==='green')
                
        //     })
        //     this.setState({
        //         CheckOut:data.map(item => item.roomStatus).filter(item=> item ==='black')
                
        //     })
            
            
        // })

        
      
    }
    
    
}


export default withRouter(Newheader);